import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import BlockContent from './block-content'
import Container from './container'

import styles from './project.module.css'

function Project (props) {
  const {_rawBody, title, categories, mainImage, relatedProjects} = props
  return (
    <Container>
      <article className='mt-20'>
        {props.mainImage && mainImage.asset && (
          <div className=''>
            <figure className='figure-project'>
              <Img
                fluid={mainImage.asset.fluid}
                alt={mainImage.alt}
                imgStyle={{objectFit: 'contain'}}
                className='lg:max-h-9/10'
              />
            </figure>
          </div>
        )}
        <div className=''>
          <div className=''>
            <h1 className={styles.title}>{title}</h1>
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
          </div>
          <aside className={styles.metaContent}>
            {categories && categories.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
            {relatedProjects && relatedProjects.length > 0 && (
              <div className={styles.relatedProjects}>
                <hr className='border-b-1 border-black py-2' />
                <h3 className={styles.relatedProjectsHeadline}>Related Images</h3>
                <ul>
                  {relatedProjects.map(project => (
                    <li key={`related_${project._id}`} className='mx-auto w-1/2 lg:w-1/4'>
                      {project.slug ? (
                        <Link to={`/project/${project.slug.current}`}><Img
                          fluid={project.mainImage.asset.fluid}
                          alt={project.mainImage.alt}
                          imgStyle={{objectFit: 'contain'}}
                          className='w-full'
                        /></Link>
                      ) : (
                        <span>{project.title}</span>
                      )}
                    </li>

                  ))}
                </ul>
                <hr className='border-b-1 border-black py-2' />
              </div>
            )}
          </aside>
        </div>
      </article>
    </Container>
  )
}

export default Project
